import axios from 'axios';

export const sendTokenToServer = async (token) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/web-notifications/save-token`, {
      token: token,
    });
    console.log('Token stored successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error storing token:', error);
    throw error; // Re-throw the error to handle it further up if necessary
  }
};
