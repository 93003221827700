import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDtZ56ciVZCK1ZqjvJW0dlEG5g_aYIm13g",
  authDomain: "libtechemployee.firebaseapp.com",
  projectId: "libtechemployee",
  storageBucket: "libtechemployee.appspot.com",
  messagingSenderId: "675426294932",
  appId: "1:675426294932:web:1250de074fa4b2846aa881",
  measurementId: "G-ETF9B0ZMLJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const generateToken = async () => {
  try {
    // Request notification permission
    const permission = await Notification.requestPermission();
    console.log('Notification permission:', permission);

    if (permission === "granted") {
      // Register the service worker
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      
      // Ensure the service worker is active
      await navigator.serviceWorker.ready;

      // Get the FCM token
      const token = await getToken(messaging, {
        vapidKey: "BF6w2owZHTIWuCgeb2EWf-We0iHuA4qUrrMCw5ILkx81eyjnK1CG_761ep7PTpak-Zc2NBmV_nC8LkJgs7XHYdI",
        serviceWorkerRegistration: registration
      });

      if (token) {
        console.log('FCM Token generated:', token);
        return token;
      } else {
        console.log('No registration token available.');
        return null;
      }
    } else {
      console.log('Notification permission denied');
      return null;
    }
  } catch (error) {
    console.error('An error occurred while generating the token:', error);
    throw error;
  }
};
