import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './i18n.js';
import './styles/global.css';
import { onMessage } from 'firebase/messaging';
import { generateToken, messaging } from './firebase.js';
import { sendTokenToServer } from './utils/notificationUtils';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// Lazy-loaded pages
const LoginPage = lazy(() => import('./pages/LoginPage'));
const LayoutComponent = lazy(() => import('./pages/LayoutComponent'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const FinancialStatusPage = lazy(() => import('./pages/FinancialStatusPage'));
const ProjectsPage = lazy(() => import('./pages/ProjectsPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const ChatPage = lazy(() => import('./pages/ChatPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const UserProfilePage = lazy(() => import('./pages/UserProfilePage'));
const NotificationsPage = lazy(() => import('./pages/NotificationsPage'));

// Create a Material UI theme with RTL direction and Zain font
const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: '"Zain", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

function App() {
  useEffect(() => {
    let isMounted = true;

    const handleToken = async () => {
      try {
        const currentToken = await generateToken();
        if (isMounted) {
          console.log(currentToken);
          if (currentToken) {
            await sendTokenToServer(currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }
      } catch (err) {
        if (isMounted) {
          console.log('An error occurred while handling the token. ', err);
        }
      }
    };

    handleToken();

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received background message:', payload);
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<LoginPage />} />
            <Route element={<LayoutComponent />}>
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="notifications" element={<NotificationsPage />} />
              <Route path="projects" element={<ProjectsPage />} />
              <Route path="finance" element={<FinancialStatusPage />} />
              <Route path="reports" element={<ReportsPage />} />
              <Route path="chat" element={<ChatPage />} />
              <Route path="settings" element={<SettingsPage />} />
              <Route path="profile" element={<UserProfilePage />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;